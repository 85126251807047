import { Fragment, lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import LoadingScreen from "./components/LoadingScreen";
import { AuthGuard, GuestGuard } from "./Guards";
import Admin from "./layouts/Admin";
import Auth from "./layouts/Auth";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

export const renderRoutes = (routes = []) => {
  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <Switch>
          {routes.map((route, i) => {
            const Guard = route.guard || Fragment;
            const Component = route.component;
            const Layout = route.layout || Fragment;

            return (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={(props) => {
                  return (
                    <Guard>
                      <Layout>
                        {route.routes ? (
                          renderRoutes(route.routes)
                        ) : (
                          <Component {...props} />
                        )}
                      </Layout>
                    </Guard>
                  );
                }}
              />
            );
          })}
        </Switch>
      </Suspense>
      <ToastContainer />
    </>
  );
};

export const routes = [
  {
    exact: true,
    guard: GuestGuard,
    path: "/login",
    layout: Auth,
    component: lazy(() => import("./views/auth/Login")),
  },
  {
    exact: true,
    path: "/forgotpassword",
    layout: Auth,
    component: lazy(() => import("./views/auth/forgotpassword.js")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: Auth,
    component: lazy(() => import("./views/auth/passwordreset")),
  },
  {
    path: "/",
    layout: Admin,
    guard: AuthGuard,
    routes: [
      {
        exact: true,
        path: "/admin",
        component: lazy(() => import("./views/admin/Dashboard")),
      },
      {
        exact: true,
        path: "/",
        component: lazy(() => import("./views/admin/Dashboard")),
      },
      {
        exact: true,
        path: "/admin/ads",
        component: lazy(() => import("./views/admin/Ads")),
      },
      {
        exact: true,
        path: "/admin/manage_admins",
        component: lazy(() => import("./views/admin/Manage_Admins")),
      },
      {
        exact: true,
        path: "/admin/admins/:adminId",
        component: lazy(() => import("./views/admin/AdminProfile")),
      },
      {
        exact: true,
        path: "/admin/manage_users",
        component: lazy(() => import("./views/admin/Manage_Users")),
      },
      {
        exact: true,
        path: "/admin/bizmix_users",
        component: lazy(() => import("./views/admin/View_Bizmix_Users")),
      },
      {
        exact: true,
        path: "/admin/user/:id",
        component: lazy(() => import("./views/Profile")),
      },
      {
        exact: true,
        path: "/admin/add-user",
        component: lazy(() => import("./views/admin/ManageUser/AddUser")),
      },
      {
        exact: true,
        path: "/admin/edit-user/:id",
        component: lazy(() => import("./views/admin/ManageUser/EditUser")),
      },
      {
        exact: true,
        path: "/admin/dashboard",
        component: lazy(() => import("./views/admin/Dashboard")),
      },
      {
        exact: true,
        path: "/admin/business",
        component: lazy(() => import("./views/admin/Business/Index")),
      },
      {
        exact: true,
        path: "/admin/business_edit/:id",
        component: lazy(() => import("./views/admin/EditBusiness__")),
      },
      {
        exact: true,
        path: "/admin/business/:businessId/locations",
        component: lazy(() => import("./views/admin/Business/Locations")),
      },
      {
        exact: true,
        path: "/admin/business/:businessId/galleries",
        component: lazy(() => import("./views/admin/Business/Gallery")),
      },
      {
        exact: true,
        path: "/admin/business/:businessId/hours",
        component: lazy(() => import("./views/admin/Business/Hours")),
      },
      {
        exact: true,
        path: "/admin/business/:businessId/children",
        component: lazy(() => import("./views/admin/Business/Children")),
      },
      {
        exact: true,
        path: "/admin/business/:businessId/infos",
        component: lazy(() => import("./views/admin/Business/EditInfos")),
      },
      {
        exact: true,
        path: "/admin/business/:businessId/kycs",
        component: lazy(() => import("./views/admin/Business/Kyc")),
      },
      {
        exact: true,
        path: "/admin/business/:businessId/contact/:contactId",
        component: lazy(() => import("./views/admin/Business/contact")),
      },
      {
        exact: true,
        path: "/admin/business/:businessId/contact",
        component: lazy(() => import("./views/admin/Business/contact_add")),
      },

      {
        exact: true,
        path: "/admin/business/:businessId/owner",
        component: lazy(() => import("./views/admin/Business/Owner")),
      },
      {
        exact: true,
        path: "/admin/roles",
        component: lazy(() => import("./views/admin/Roles/Index")),
      },
      {
        exact: true,
        path: "/admin/roles/create",
        component: lazy(() => import("./views/admin/Roles/Create")),
      },
      {
        exact: true,
        path: "/admin/roles/:roleId/edit",
        component: lazy(() => import("./views/admin/Roles/Edit")),
      },
      {
        exact: true,
        path: "/admin/adverts",
        component: lazy(() => import("./views/admin/Advert/Index")),
      },
      {
        exact: true,
        path: "/admin/adverts/create",
        component: lazy(() => import("./views/admin/Advert/Create")),
      },
      {
        exact: true,
        path: "/admin/adverts/:packageId/edit",
        component: lazy(() => import("./views/admin/Advert/Edit")),
      },
      {
        exact: true,
        path: "/admin/adverts/:packageId/plans",
        component: lazy(() => import("./views/admin/Advert/Plans/Index")),
      },
      {
        exact: true,
        path: "/admin/adverts/:packageId/plans/create",
        component: lazy(() => import("./views/admin/Advert/Plans/Create")),
      },
      {
        exact: true,
        path: "/admin/adverts/:packageId/plans/:planId/edit",
        component: lazy(() => import("./views/admin/Advert/Plans/Edit")),
      },
      {
        exact: true,
        path: "/admin/subscription-plans",
        component: lazy(() =>
          import("./views/admin/FeaturedSubscriptionPlan/Index")
        ),
      },
      {
        exact: true,
        path: "/admin/subscription-plans/:planId/edit",
        component: lazy(() =>
          import("./views/admin/FeaturedSubscriptionPlan/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/featured-model",
        component: lazy(() => import("./views/admin/FeaturedModel/Index")),
      },
      {
        exact: true,
        path: "/admin/featured-listing-client",
        component: lazy(() =>
          import("./views/admin/FeaturedListingClient/featuredListing")
        ),
      },
      {
        exact: true,
        path: "/admin/featured-model/:modelId/terminate",
        component: lazy(() => import("./views/admin/FeaturedModel/Terminate")),
      },
      {
        exact: true,
        path: "/admin/featured-model/:modelId/details",
        component: lazy(() => import("./views/admin/FeaturedModel/Details")),
      },
      {
        exact: true,
        path: "/admin/subscription-plans/create",
        component: lazy(() =>
          import("./views/admin/FeaturedSubscriptionPlan/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/profile",
        component: lazy(() => import("./views/admin/Admin_Profile")),
      },
      {
        exact: true,
        path: "/admin/listing/:slug",
        component: lazy(() => import("./views/admin/ListingTable")),
      },

      {
        exact: true,
        path: "/admin/listing/details/:id",
        component: lazy(() => import("./views/admin/ListingDetails")),
      },
      {
        exact: true,
        path: "/admin/tables",
        component: lazy(() => import("./views/admin/Tables")),
      },
      {
        exact: true,
        path: "/admin/claim-business-request",
        component: lazy(() =>
          import(
            "./views/admin/ClaimBusinessConnection/AllClaimBusinessConnection"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category",
        component: lazy(() =>
          import("./views/admin/ListingCategory/ListingCategory")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/create",
        component: lazy(() => import("./views/admin/ListingCategory/Create")),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/edit",
        component: lazy(() => import("./views/admin/ListingCategory/Edit")),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/properties",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/Properties/ListingCategoryProperties"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/create",
        component: lazy(() =>
          import("./views/admin/ListingCategory/Properties/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/edit",
        component: lazy(() =>
          import("./views/admin/ListingCategory/Properties/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/values",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/Properties/Values/ListingCategoryPropertyValues"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/values/create",
        component: lazy(() =>
          import("./views/admin/ListingCategory/Properties/Values/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/values/:listingCategoryPropertyValueId/edit",
        component: lazy(() =>
          import("./views/admin/ListingCategory/Properties/Values/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/extras/create",
        component: lazy(() =>
          import("./views/admin/ListingCategory/Properties/Extras/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/extras/:listingCategoryPropertyExtraId/edit",
        component: lazy(() =>
          import("./views/admin/ListingCategory/Properties/Extras/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/extras",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/Properties/Extras/ListingCategoryPropertyExtras"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/extras/:listingCategoryPropertyExtraId/values",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/Properties/Extras/Values/ListingCategoryPropertyExtraValues"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/extras/:listingCategoryPropertyExtraId/values/create",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/Properties/Extras/Values/Create"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/property/:listingCategoryPropertyId/extras/:listingCategoryPropertyExtraId/values/:listingCategoryPropertyExtraValueId/edit",
        component: lazy(() =>
          import("./views/admin/ListingCategory/Properties/Extras/Values/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/sub-category",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/SubCategory/ViewListingSubCategory"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/sub-category/create",
        component: lazy(() =>
          import("./views/admin/ListingCategory/SubCategory/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/sub-category/:listingSubCategoryId/edit",
        component: lazy(() =>
          import("./views/admin/ListingCategory/SubCategory/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/sub-category/:listingSubCategoryId/properties",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/SubCategory/Property/ViewListingSubCategoryProperty"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/sub-category/:listingSubCategoryId/property/create",
        component: lazy(() =>
          import("./views/admin/ListingCategory/SubCategory/Property/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/sub-category/:listingSubCategoryId/property/:listingSubCategoryPropertyId/edit",
        component: lazy(() =>
          import("./views/admin/ListingCategory/SubCategory/Property/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/sub-category/:listingSubCategoryId/property/:listingSubCategoryPropertyId/values",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/SubCategory/Property/Value/ListingSubCategoryPropertyValues"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/sub-category/:listingSubCategoryId/property/:listingSubCategoryPropertyId/values/create",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/SubCategory/Property/Value/Create"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/listing-category/:listingCategoryId/sub-category/:listingSubCategoryId/property/:listingSubCategoryPropertyId/values/:listingSubCategoryPropertyValueId/edit",
        component: lazy(() =>
          import(
            "./views/admin/ListingCategory/SubCategory/Property/Value/Edit"
          )
        ),
      },
      {
        exact: true,
        path: "/admin/category",
        component: lazy(() => import("./views/admin/BusinessCategory/Index")),
      },
      {
        exact: true,
        path: "/admin/category/create",
        component: lazy(() => import("./views/admin/BusinessCategory/Create")),
      },
      {
        exact: true,
        path: "/admin/category/:businessCategoryId/edit",
        component: lazy(() => import("./views/admin/BusinessCategory/Edit")),
      },
      {
        exact: true,
        path: "/admin/category/:businessCategoryId/sub-category",
        component: lazy(() =>
          import("./views/admin/BusinessCategory/SubCategory/Index")
        ),
      },
      {
        exact: true,
        path: "/admin/category/:businessCategoryId/sub-category/create",
        component: lazy(() =>
          import("./views/admin/BusinessCategory/SubCategory/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/category/:businessCategoryId/sub-category/:businessSubCategoryId/edit",
        component: lazy(() =>
          import("./views/admin/BusinessCategory/SubCategory/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/add-listing/:id",
        component: lazy(() => import("./views/admin/AddListing")),
      },
      {
        exact: true,
        path: "/admin/edit-listing/:id",
        component: lazy(() => import("./views/admin/EditListing")),
      },
      {
        exact: true,
        path: "/admin/audit-log",
        component: lazy(() => import("./views/admin/AuditLog/index")),
      },

      {
        exact: true,
        path: "/admin/user-log",
        component: lazy(() => import("./views/admin/AuditLog/UserLogs")),
      },

      {
        exact: true,
        path: "/admin/audit-log/summary",
        component: lazy(() => import("./views/admin/AuditLog/AuditSummary")),
      },
      {
        exact: true,
        path: "/admin/payment-transaction",
        component: lazy(() =>
          import("./views/admin/PaymentTransactions/Index")
        ),
      },
      {
        exact: true,
        path: "/admin/realtime-revenue",
        component: lazy(() =>
          import("./views/admin/PaymentTransactions/RealtimeRevenue")
        ),
      },
      {
        exact: true,
        path: "/admin/gmv",
        component: lazy(() => import("./views/admin/PaymentTransactions/GMV")),
      },
      {
        exact: true,
        path: "/admin/buyer-to-seller",
        component: lazy(() =>
          import("./views/admin/PaymentTransactions/BuyerToSellerKPI")
        ),
      },
      {
        exact: true,
        path: "/admin/payment-transaction/create",
        component: lazy(() =>
          import("./views/admin/PaymentTransactions/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/business/create",
        component: lazy(() => import("./views/admin/Business/CreateBusiness")),
      },
      {
        exact: true,
        path: "/admin/newsletters",
        component: lazy(() => import("./views/admin/Newsletter/Index")),
      },
      {
        exact: true,
        path: "/admin/newsletters/create",
        component: lazy(() => import("./views/admin/Newsletter/Create")),
      },

      {
        exact: true,
        path: "/admin/banners/create",
        component: lazy(() => import("./views/admin/Banner/Create")),
      },
      {
        exact: true,
        path: "/admin/banners",
        component: lazy(() => import("./views/admin/Banner/Index")),
      },
      {
        exact: true,
        path: "/admin/banners/:bannerId/edit",
        component: lazy(() => import("./views/admin/Banner/Edit")),
      },

      {
        exact: true,
        path: "/admin/sales-leads/create",
        component: lazy(() => import("./views/admin/SalesLead/Create")),
      },
      {
        exact: true,
        path: "/admin/sales-leads",
        component: lazy(() => import("./views/admin/SalesLead/Index")),
      },
      {
        exact: true,
        path: "/admin/sales-leads/:salesLeadId/edit",
        component: lazy(() => import("./views/admin/SalesLead/Edit")),
      },
      {
        exact: true,
        path: "/admin/club-connect",
        component: lazy(() => import("./views/admin/ClubConnect/Index")),
      },
      {
        exact: true,
        path: "/admin/club-connect/create",
        component: lazy(() => import("./views/admin/ClubConnect/Create")),
      },
      {
        exact: true,
        path: "/admin/club-connect/:planId/edit",
        component: lazy(() => import("./views/admin/ClubConnect/Edit")),
      },
      {
        exact: true,
        path: "/admin/cc-course",
        component: lazy(() => import("./views/admin/ClubConnect/Course/Index")),
      },
      {
        exact: true,
        path: "/admin/cc-eventsTraining",
        component: lazy(() =>
          import("./views/admin/ClubConnect/EventsTraining/Index")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-eventsTraining/details/:eventTrainingId",
        component: lazy(() =>
          import("./views/admin/ClubConnect/EventsTraining/Details")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-course/:courseId/users",
        component: lazy(() =>
          import("./views/admin/ClubConnect/Course/CourseUsers")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-course/create",
        component: lazy(() =>
          import("./views/admin/ClubConnect/Course/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-course/:courseId/edit",
        component: lazy(() => import("./views/admin/ClubConnect/Course/Edit")),
      },
      {
        exact: true,
        path: "/admin/cc-article",
        component: lazy(() =>
          import("./views/admin/ClubConnect/Article/Index")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-cac",
        component: lazy(() => import("./views/admin/ClubConnect/Cac/Index")),
      },
      {
        exact: true,
        path: "/admin/bizmix-event",
        component: lazy(() => import("./views/admin/Bizmix/Index")),
      },
      {
        exact: true,
        path: "/admin/bizmix-event/create",
        component: lazy(() => import("./views/admin/Bizmix/Create")),
      },
      {
        exact: true,
        path: "/admin/bizmix-event/:eventId/edit",
        component: lazy(() => import("./views/admin/Bizmix/Edit")),
      },
      {
        exact: true,
        path: "/admin/state",
        component: lazy(() => import("./views/admin/State/Index")),
      },
      {
        exact: true,
        path: "/admin/state/:stateId/city",
        component: lazy(() => import("./views/admin/State/Cities/Index")),
      },
      {
        exact: true,
        path: "/admin/state/:stateId/city/create",
        component: lazy(() => import("./views/admin/State/Cities/Create")),
      },
      {
        exact: true,
        path: "/admin/state/:stateId/city/:cityId/edit",
        component: lazy(() => import("./views/admin/State/Cities/Edit")),
      },
      {
        exact: true,
        path: "/admin/state/:stateId/city/:cityId/local-government/:localGovernmentId/edit",
        component: lazy(() =>
          import("./views/admin/State/Cities/LocalGovernment/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/state/:stateId/city/:cityId/local-government",
        component: lazy(() =>
          import("./views/admin/State/Cities/LocalGovernment/Index")
        ),
      },
      {
        exact: true,
        path: "/admin/state/:stateId/city/:cityId/local-government/create",
        component: lazy(() =>
          import("./views/admin/State/Cities/LocalGovernment/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-merchant",
        component: lazy(() =>
          import("./views/admin/ClubConnect/Merchant/Index")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-merchant/create",
        component: lazy(() =>
          import("./views/admin/ClubConnect/Merchant/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-merchant/:merchantId/edit",
        component: lazy(() =>
          import("./views/admin/ClubConnect/Merchant/Edit")
        ),
      },

      {
        exact: true,
        path: "/admin/cc-merchant/:merchantId/product",
        component: lazy(() =>
          import("./views/admin/ClubConnect/Merchant/Product/Index")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-merchant/:merchantId/product/:productId/edit",
        component: lazy(() =>
          import("./views/admin/ClubConnect/Merchant/Product/Edit")
        ),
      },
      {
        exact: true,
        path: "/admin/cc-merchant/:merchantId/product/create",
        component: lazy(() =>
          import("./views/admin/ClubConnect/Merchant/Product/Create")
        ),
      },
      {
        exact: true,
        path: "/admin/market-budget",
        component: lazy(() =>
          import("./views/admin/PaymentTransactions/MarketBudget")
        ),
      },
      {
        exact: true,
        path: "/admin/market-budget/update",
        component: lazy(() =>
          import("./views/admin/PaymentTransactions/UpdateMarketBudget")
        ),
      },
      {
        exact: true,
        path: "/admin/monthly-cac",
        component: lazy(() =>
          import("./views/admin/PaymentTransactions/MonthlyCAC")
        ),
      },
      {
        exact: true,
        path: "/admin/constant-contact",
        component: lazy(() => import("./views/admin/ConstantContact/Index")),
      },
      {
        exact: true,
        path: "/admin/constant-contact",
        component: lazy(() => import("./views/admin/ConstantContact/Index")),
      },
      {
        exact: true,
        path: "/admin/christmas-campaign",
        component: lazy(() =>
          import("./views/admin/CampaignAbandonedCart/Index")
        ),
      },
      {
        exact: true,
        path: "/admin/cntv",
        component: lazy(() => import("./views/admin/Cntv/Index")),
      },
      {
        exact: true,
        path: "/admin/cntv/:catId",
        component: lazy(() => import("./views/admin/Cntv/Posts/Index")),
      },
      {
        exact: true,
        path: "/admin/articles",
        component: lazy(() => import("./views/admin/Articles/Index")),
      },
    ],
  },
];
